.entry-content {
  font-size: 1em;
  line-height: 1.5em;
  p {
    margin-bottom: 20px;
  }
  a {
    display: inline-block;
    text-decoration: none;
    color: $brand-primary;
    padding-bottom: 2px;
    border-bottom: 1px solid $link-underline-color;
  }
}
.entry-feature-image {
  &--float-right {
    float: right;
    margin-left: 35px;
    margin-bottom: 35px;
    @media screen and ( max-width: $screen-sm ) {
      width: 100%;
    }
  }
}
.entry-full-width-image {
  text-align: center;
  margin-top: 35px;
  img {
    max-width: 100%;
  }
}
