#breadcrumbs {
  color: $brand-primary;
  font-size: 1em;
  // font-style: italic;
  line-height: 1.29em;
  margin-bottom: 45px;

  a {
    color: $brand-primary;
    text-decoration: none;
  }
}
