$footer-padding: 0px 45px;
$footer-height-sm: 146px;

#footer {
  display: flex;
  align-items: center;
  height: $footer-height;
  padding: $footer-padding;
  background-color: $brand-primary;
  color: $white;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;

  @media screen and (max-width: $screen-sm) {
    height: $footer-height-sm;
    align-items: flex-start;
    padding: 0;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: $screen-sm) {
      flex-direction: column-reverse;
    }
  }
}

.social {
  font-size: 0;
  @media screen and (max-width: $screen-sm) {
    margin: 20px;
  }
}

.copyright {
  @include fs-small;
  letter-spacing: 0.7px;

  @media screen and (max-width: $screen-sm) {
    text-align: center;
  }
}
