$soapbox-bgcolor: #DEDCDC;
$soapbox-marquee-padding: 40px 0;
$soapbox-wrapper-margin:  45px 0;

// Hack to remove the first container in order to achieve full bleed in the content area on the soapbox page
.soapbox header ~ .wrap {
  display: none;
}
.page-template-template-soapbox .main {
  position: relative;
}
.soapbox-marquee {
  padding: $soapbox-marquee-padding;
  width: 100%;
  height: 316px;
  background-color: $soapbox-bgcolor;
  .wrap {
    @media screen and ( max-width: $screen-sm ) {
      margin-top: 0px !important;
    }
  }
  @media screen and ( max-width: 1200px ) {
    height: auto;
  }
  @media screen and ( max-width: 767px ) {
    padding-top: 80px;
  }
  &-innerwrapper {
    display: flex;
  }
  &-title {
    font-weight: bold;
    font-size: 1em;
    text-transform: uppercase;
    color: $brand-secondary;
    letter-spacing: 1px;
    margin-bottom: 25px;
    margin-top: 20px;
  }
  &-image {
    margin-right: 45px;
    @media screen and (max-width: 1000px) {
      display: none;
    }
  }
  .content {
    margin: 0;
  }
  p {
    font-size: 1.1em;
    line-height: 1.35em;
  }
}
.soapbox-wrapper {
  margin: $soapbox-wrapper-margin;
  @media screen and (max-width: $screen-sm) {
    margin-top: 0;
  }
  article {
    margin-bottom: 33px;
  }
  article:nth-of-type(n + 2) {
    margin-top: 47px;
  }
  a {
    text-decoration: none;
    color: $brand-primary;
  }
}
.soapbox-article {
  padding-right: 40px;
  @media screen and (max-width: $screen-md) {
    padding-right: 0px;
  }
}
.article-title {
  font-weight: bold;
  @include h2;
  line-height: 1.225em;
  letter-spacing: 1.5px;
}
.article-credits {
  margin-bottom: 25px;
  margin-top: 15px;
}
.article-date, .article-author {
  font-size: 0.88em;
}
.article-author {
  margin-top: 10px;
}
.article-featured-image {
  margin-bottom: 34px;
  margin-right: 34px;
  .single-soapbox-article & {
    float: left;
  }
  @media screen and ( max-width: $screen-sm ) {
    .single-soapbox-article & {
      width: 100%;
    }
    img {
      max-width: 100%;
    }
  }
}
.article-excerpt, .article-content {
  font-size: 1em;
  line-height: 1.38em;
  margin-bottom: 20px;
}
.article-content {
  p {
    margin-bottom: 22px;
  }
}
.soapbox-cta {
  width: 100%;
  padding: 20px;
  min-height: 100px;
  background-color:#DEDCDC;
  p {
    line-height: 1.38em;
  }
  @media screen and (max-width: 1000px) {
    display: none;
  }
}
.soapbox-cta-title {
  font-weight: bold;
  font-size: 1em;
  text-transform: uppercase;
  color: $brand-secondary;
  letter-spacing: 1px;
  margin-bottom: 25px;
}
.soapbox-cta-link {
  text-decoration: none;
  color: $brand-primary;
  border-bottom: 1px solid $link-underline-color;
}
.soapbox-wrapper .article-readmore {
  color: $brand-secondary;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
  letter-spacing: 0.8px;
  line-height: 1.45em;
}
