.contact-wrapper {
  display: flex;

  @media screen and ( max-width: $screen-sm ) {
    flex-direction: column-reverse;
  }
}
.contact-group {
  margin: 20px 0;
  margin-right: 10px;
  @media screen and (max-width: $screen-sm) {
    margin-right: 0px;
  }
  &-header {
    font-weight: bold;
    @include h3;
    color: $brand-secondary;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 5px;
  }
  &-body {
    @include p;
    line-height: 1.5em;
  }
}
