$marquee-innerwrapper-margin: 60px 40px;

.headline-marquee {
  background-color: $light-grey;
  display: flex;

  @media screen and (max-width: $screen-sm) {
    flex-direction: column;
  }

  &-image {
    background-size: cover;
    width: 55%;
    flex-shrink: 0;
    background-position: 50% 17%;
    @media screen and (max-width: $screen-sm) {
      height: 400px;
      width: 100%;
      margin-top: 59px;
    }
  }

  &-text {
    h1 {
      @include h1;
      margin: 20px 0;
    }
    h2 {
      @include p;
      margin: 20px 0;
    }
  }
  &-caption {
    @include h4;
    display: flex;
    flex-wrap: wrap;
    line-height: 1.5em;
    font-weight: bold;
    @media screen and (max-width: $screen-sm) {
      display: block;
    }
    a {
      text-decoration: none;
      color: $brand-secondary;
    }
    p {
      width: 60%;
      margin-right: 10px;
      margin-bottom: 20px;
      @media screen and (max-width: $screen-sm) {
        margin-bottom: 20px;
        width: 100%;
      }
    }
  }
}
.marquee-text-innerwrapper {
  margin: $marquee-innerwrapper-margin;
  max-width: 450px;
  width: 80%;
  a {
    text-decoration: none;      
  }
  h1 {
    font-weight: bold;
  }
  @media screen and (max-width: $screen-sm) {
    max-width: 100%;
  }
}

