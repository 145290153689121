$comment-timestamp-color: #535554;

#comments {
  margin-top: 30px;

  h2 {
    @include h4;
  }
  .avatar {
    display: none;
  }
  b a {
    text-decoration: none;
    color: $brand-primary;
  }
}
#comment {
  display: block;
}
.comment-form-author, .comment-form-email, .comment-form-url {
  display: block;
  margin: 5px 0;
  label {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 0.8em;
  }
}
.comment-form-comment {
  label {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 0.8em;
    margin-bottom: 5px;
    display: block;
  }
}
.comments-title {
  font-size: 1em;
  letter-spacing: 1px;
  color: $brand-secondary;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.comments-description, .comment-notes {
  font-size: 0.88em;
  line-height: 1.3em;
  margin-bottom: 10px;
}
.comment-list {
}
.comment-list ol {
  list-style: none;
}
.comment-respond {
  margin-top: 20px;
}
.comment-form p {
  @extend .form-group;
}
.comment-form input[type="text"],
.comment-form input[type="email"],
.comment-form input[type="url"],
.comment-form textarea {
  @extend .form-control;
}
.comment-form input[type="submit"] {
  @extend .btn;
  @extend .btn-primary;
}
.comment-meta {
  background-color: $white;
  color: $brand-primary;
  padding: 0;
  display: block;
  height: inherit;
}
.comment-body {
  margin-top: 10px;
  margin-bottom: 20px;
}
.comment-author {
  font-size: 1em;
  font-weight: 600;
}
.comment-metadata, .logged-in-as {
  font-size: 0.8em;
  margin-top: 5px;
  margin-bottom: 20px;
  a {
    text-decoration: none;
    color: $comment-timestamp-color;
  }
}
.comment-awaiting-moderation {
  display: none;
}
.reply {
  a {
    font-size: (14 / 16) * 1em;
    color: $brand-secondary;    
    text-decoration: none;
    margin-top: 19px;
    display: inline-block;
  }
}

.comment-content {
  font-size: (14 / 16) * 1em;
}
ol.children {
  margin-bottom: 20px;
  li:nth-of-type(odd) {
      article {
        padding: 20px;
        background-color: #F7F7F7;
      .comment-meta {
        background-color: #F7F7F7;
     }
    }
  }
  width: 95%;
  margin-left: auto;
}
#reply-title {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 20px;
}
#email-notes {
  display: block;
  margin-bottom: 15px;
}
.comment-form {
  label {
    display: none;
  }
  input, textarea {
    background-color: #F7F7F7;
    border: 0;
    width: 50%;
    padding: 10px;
    font-size: (14 / 16) * 1em;
    margin: 8px 0px;
    @media screen and (max-width: $screen-sm) {
      width: 100%;
    }
  }
}
#respond {
  // padding: 20px;
  @media screen and (max-width: $screen-sm) {
    padding: 20px 0;
  }
}
#cancel-comment-reply-link {
  color: $brand-secondary;
  text-decoration: none;
  display: block;
  margin-top: 10px;
  font-size: (14 / 16) * 1em;
}
#submit {
  cursor: pointer;
}