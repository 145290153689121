$incubator-margin: 53px 0;

.incubator-summary {
  margin-bottom: 49px;
}
.incubator-project {
  margin: $incubator-margin;
  &-title {
    @include h2;
    font-weight: bold;
    color: $brand-secondary;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 25px;
  }
  &-content {
    float: left;
    width: 70%;
    margin-right: 2%;
    p {
      line-height: 1.5em;
      margin-bottom: 25px;
    }
    @media screen and ( max-width: $screen-sm ){
      width: 100%;
    }
  }
  &-image {
    width: 28%;
    float: left;
    img {
      max-width: 100%;
    }
    @media screen and ( max-width: $screen-sm ) {
      width: 100%;
    }
  }
  &:after {
    content: " ";
    display: block;
    clear: both;
  }
}
