$header-height: 198px;
$header-height-sm: 146px;

.brand {
  text-decoration: none;
}

.banner {
  position: relative;
  height: $header-height;
  background-color: $brand-primary;
  background-image: url('../images/topbanner.jpg');
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  @media screen and (max-width: $screen-sm) {
    height: $header-height-sm;
  }
  @media screen and (max-width: $screen-xs) {
    background-image: none;
  }
}

header .container {
  display: flex;
  position: relative;
  justify-content: center;
  padding-top: 15px;

  @media screen and ( max-width: $screen-sm ) {
    position: relative;
    top: 15px;
  }
}

.site-logo {
  text-align: center;
  img {
    width: 200px;
  }
  &__fallback {
    @include h1;
    color: $white;
  }
}
#sizer {
  position: absolute;
  top: 0;
  left: 0;
}
