$summary-bottom-margin: 63px;
$feature-artist-margin: 43px 0 70px 0;
$past-artists-margin: 46px 0 97px 0;

.featured-artists {
  &-summary {
    margin-bottom: $summary-bottom-margin;
  }
}
.featured-artist {
  display: flex;
  flex-wrap: wrap;
  margin: $feature-artist-margin;
  margin-top: 43px;
  &-columns {
    display: flex;
    @media screen and ( max-width: $screen-sm ) {
      flex-direction: column-reverse;
    }
  }
  &-name {
    font-weight: bold;
    width: 100%;
    margin-bottom: 26px;
    letter-spacing: 2px;
    color: $brand-secondary;
    @include h2;
    a {
      text-decoration: none;
      color: $brand-secondary;
    }
  }
  &-description {
    p {
      margin-bottom: 20px;
      line-height: 1.5em;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  &-headshot {
    margin-left: 30px;
    img {
      max-width: 100%;
    }
    @media screen and ( max-width: $screen-sm ) {
      margin-left: 0px;
    }
  }
}
.featured-artist-column {
  &--two-thirds {
    width: 66.6%;
    @media screen and ( max-width: $screen-sm ) {
      width: 100%;
      margin-top: 40px;
    }
  }
  &--one-third {
    width: 33.3%;
    flex-grow: 1;
    @media screen and ( max-width: $screen-sm ) {
      width: 100%;
    }
  }
}

.past-artists {
  margin: $past-artists-margin;
  &-title {
    font-weight: bold;
    @include h2;
    letter-spacing: 2px;
    margin-bottom: 26px;
  }
}
.past-artist-carousel {
  display: flex;
  flex-wrap: wrap;
}
.past-artist {
  margin-bottom: 50px;
  width: 33.3%;
  @media screen and ( max-width: $screen-md ) {
   width: 50%;
  }
  @media screen and ( max-width: $screen-sm ) {
    width: 100%;
  }
  &-headshot {
    width: 213px;
    height: 213px;
    background-size: cover;
  }
  &-title {
    font-weight: bold;
    margin-top: 15px;
    font-size: 1.13em;
  }
}
