$resource-width: calc(33.3% - 34px);
$card-margin: 18px 17px;
$card-padding: 45px 40px;

.card-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.card {
  background-color: $light-grey;
  padding: $card-padding;
  margin: $card-margin;

  @media screen and (max-width: $screen-sm) {
    flex-direction: column-reverse;
    margin: 18px 0px;
  }

  &--has-columns {
    display: flex;
    flex-grow: 1;
    margin-left: 0;
    margin-bottom: 35px;
  }
  &--full-bleed {
    padding: 0;
  }
  &-column {
    &--projects {
      padding: $card-padding;
      @media screen and (max-width: $screen-md) {
        padding: 45px 20px;
      }
    }
    &--two-thirds {
      width: 66%;
      @media screen and (max-width: $screen-sm) {
        width: 90%;
      }
    }
    &--one-third {
      flex-grow: 1;
      width: 33.3%;
      @media screen and (max-width: $screen-sm) {
        width: 100%;
      }
    }
    &--has-image {
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      @media screen and (max-width: $screen-sm) {
        height: 400px;
      }
    }
  }
  &--resource {
    width: $resource-width;
    @media screen and ( max-width: $screen-md ) {
      width: calc(50% - 34px);
    }
    @media screen and ( max-width: $screen-sm ) {
      width: 100%;
    }
  }
  .page-template-template-resources &:nth-of-type(3n + 1) {
    @media screen and (min-width: $screen-md) {
      margin-left: 0;
    }
  }
  &-category {
    @include h4;
    font-weight: 500;
    letter-spacing: 1.3px;
    margin-bottom: 10px;
  }
  &-title {
    font-weight: bold;
    margin: 10px 0px 0px 0px;
    font-size: 1.125em;
    letter-spacing: 0.95px;
    line-height: 1.18em;
  }
  &-dop {
    font-size: 0.88em;
    color: $brand-primary;
    letter-spacing: 0.74px;
    line-height: 21px;
  }
  &-summary {
    font-size: 0.88em;
    line-height: 20px;
    margin-top: 9px;
  }
  &-download {
    text-transform: capitalize;
    display: inline-block;
    text-decoration: none;
    color: $brand-primary;
    margin-top: 20px;
    font-size: 0.88em;
    padding-bottom: 4px;
    border-bottom: 1px solid $link-underline-color;
  }
}
