/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
  overflow-x: hidden;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
*, *:before, *:after {
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  -ms-box-sizing:border-box;
  box-sizing:border-box;
}
html {
  height: 100%;
}
body {
  font-family: $primary-font;
  font-size: $base-fontsize * 1px;
  padding-bottom: $footer-height;
  min-height: 100%;
  position: relative;
  @media screen and (max-width: $screen-sm) {
    padding-bottom: $footer-height-sm;
  }
}
strong {
  font-weight: 600;
}
em {
  font-style: italic;
}
u {
  text-decoration: underline;
}
input, textarea {
    &::placeholder {
      color: #000;
    }
}
p {
  line-height: 1.45em;
}
.container {
  width: 80%;
  // @media screen and (max-width: 1200px) {
  //   padding: 0 70px;
  // }
  @media screen and (max-width: $screen-sm) {
    padding: 0 30px;
    width: 100%;
  }
}
.content {
  margin: 30px auto;
  // width: 90%;
  @media screen and (max-width: $screen-sm) {
    margin: 50px 0px;
  }
}

.page-header {
    @include h2;
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom: 15px;
}
.wrap {
  @media screen and (max-width: $screen-sm) {
    margin-top: 90px !important;
  }
}

// Posts

.summary {
  @include p;
  line-height: 1.5em;
  letter-spacing: 0.57px;
}

.caption {
  margin-top: 15px;
  line-height: 1.24em;
  font-size: 0.813em;
  color: $brand-primary;
}


// Breakpoint Specific Classes
.desktop-hidden {
  display: none;
  @media screen and (max-width: $screen-sm) {
    display: block;
  }
}

.permalink {
  display: inline-block;
  text-decoration: none;
  color: $brand-primary;
  margin-top: 20px;
  padding-bottom: 4px;
  border-bottom: 1px solid $link-underline-color;
}
.skip-links {
  margin: 0;
  padding: 0;
  list-style-type: none;
  padding: 0.5em 0.75em;
  font-weight: 600;
}
.skip-links a {
  position: absolute;
  top: -3em;
  background-color: #fff;
  color: #000;
}
.skip-links a:focus {
  top: 0;
}
.fb_iframe_widget span {
  vertical-align: baseline !important;
}

.mobile-hidden {
  display: block;
  @media screen and (max-width: $screen-sm) {
    display: none;
  }
}
