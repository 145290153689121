$mission-height: 288px;
$mission-padding: 50px;

.about-wrapper {
  display: flex;
  margin-bottom: 50px;
  @media screen and ( max-width: $screen-sm ) {
    flex-direction: column-reverse;
  }
}
.about-summary {
  @include p;
  width: 55%;
  margin-right: 30px;
  p {
    margin-bottom: 35px;
  }
  a {
    display: inline-block;
    text-decoration: none;
    color: $brand-primary;
    padding-bottom: 4px;
    border-bottom: 1px solid $link-underline-color;
  }
  @media screen and ( max-width: $screen-sm ) {
    width: 100%;
    margin-top: 35px;
    margin-bottom: 0;
  }
}
.about-feature img {
  max-width: 100%;
}
.mission {
  background-color: #5B1005;
  height: $mission-height;
  @media screen and ( max-width: $screen-sm ) {
    height: auto;
  }
}
.mission-title {
  font-weight: bold;
}
.mission-wrapper {
  max-width: $max-width;
  margin: 0 auto;
  padding: $mission-padding 0;
  text-align: center;
  h2 {
    color: $white;
    @include h2;
    letter-spacing: 2px;
    margin-bottom: 17.5px;
  }
  p {
    font-size: 1.13em;
    line-height: 1.44em;
    max-width: 80%;
    margin: 0 auto;
    color: $white;
  }
}

.site-makers {
  margin-bottom: 123px;
  h2 {
    @include h2;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin-bottom: 15px;
    margin-top: 55px;
  }
  p {
    @include p;
    line-height: 1.5em;
    @media screen and ( max-width: $screen-sm ) {
      margin: 10px 0;
    }
  }
}
.maker-role {
  font-weight: bold;
}
