.gallery {
  margin-bottom: 40px;
}
.gallery-title {
  font-weight: bold;
  font-size: 1.13em;
  letter-spacing: 0.9px;
  margin-bottom: 20px;
  margin-top: 40px;
}
.gallery-images {
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: $screen-sm) {
    flex-direction: column;
    text-align: center
  }
  li {
    margin-bottom: 22px;
    margin-right: 20px;
    width: 30%;
    img {
      width: 100%;
    }
    @media screen and (max-width: $screen-md) {
      width: 46%;
    }
    @media screen and (max-width: $screen-sm) {
      width: 100%;
    }

  }
}