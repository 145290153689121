.search-wrap {
  border: 1px solid $white;
  border-right-width: 0px;
  border-top-width: 0px;
  label {
    display: inline-block;
    vertical-align: middle;
  }
}
#search-input {
  min-width: 194px;
}
#search-input, #search-input-mobile {
  @include p;
  background: transparent;
  border: 0;
  height: 37px;
  letter-spacing: 0.74px;
  font-size: 0.9em;
  color: $white;
  &::placeholder {
    color: $white;
    @media screen and (max-width: $screen-sm) {
      color: #000;
    }
  }
}
#search-submit {
  display: none;
}
.screen-reader-text {
  visibility: hidden;
}
.magnifying-glass {
  width: 17px;
  height: 19px;
  position: relative;
  margin-left: 15px;
}
#search-form {
  position: absolute;
  top: 0;
  right: 0;
}
#search-form-mobile {
  position: absolute;
  top: 0;
  right: 0;
}
#search-input-mobile {
  width: 0px;
  background: none;
  border: none;
  visibility: hidden;
  color: $white;
}
