.single-projects {
  h1 {
    margin-bottom: 8px;
  }
  #breadcrumbs {
    margin-bottom: 26px;
  }
}
.project-feature {
  margin-right: 35px;
  @media screen and ( max-width: $screen-sm ) {
    margin-right: 0px;
    margin-top: 35px;
    img {
      max-width: 100%;
    }
  }
}
.project-content {
  display: flex;
  margin-top: 27px;
  margin-bottom: 30px;
  @media screen and ( max-width: $screen-sm ) {
    flex-direction: column-reverse;
  }
}
.project-date {
  @include fs-small;
  color: $brand-primary;
}
.project-description {
  @include fs-base;
  line-height: 1.5em;
  a {
    display: inline-block;
    text-decoration: none;
    color: $brand-primary;
    padding-bottom: 4px;
    border-bottom: 1px solid $link-underline-color;
  }
}

.video {
  margin-top: 40px;
}
.video-title {
  font-weight: bold;
  font-size: 1.13em;
  letter-spacing: 0.9px;
  margin-bottom: 12px;
}
.video-details {
  margin-left: 20px;
  font-size: 0.7em;
  letter-spacing: 0.7px;

}
.video-link-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  img {
    // width: 25px;
    // height: 24px;
    margin-right: 20px;
    @media screen and (max-width: $screen-sm) {
      min-width: 25px;
    }
  }
}
.video-link {
  display: block;
  letter-spacing: 0.8px;
  font-size: 1em;
  line-height: 1.2em;
  text-decoration: none;
  color: $brand-secondary;
  font-weight: 500;
  @media screen and (max-width: $screen-sm) {
    margin-bottom: 20px;
    line-height: 1.5em;
  }
}
.video-player {
  margin-top: 7px;
}
.video-description {
  margin-top: 22px;
  line-height: 1.5em;
}
