.home {
  .content {
    margin-top: 0;
  }
  .wrap.container:first-of-type {
    display: none;
  }
  .page-header {
    display: none;
  }
}
.home-link {
  display: inline-block;
  text-decoration: none;
  color: $brand-primary;
  margin-top: 28px;
  font-size: 1em;
  padding-bottom: 4px;
  border-bottom: 1px solid $link-underline-color;
}
.home-project {
  display: flex;
  margin-top: 60px;
  margin-bottom: 47px;
  & > a {
    width: 50%;
    @media screen and (max-width: 1000px) {
      width: 100%;
    }
  }
  @media screen and ( max-width: 1000px ) {
    flex-direction: column;
  }
}
.home-project-image {
  margin-right: 55px;
  img {
    max-width: 100%;
  }
  @media screen and ( max-width: $screen-sm ) {
    margin-right: 0;
    margin-bottom: 55px;
  }
}
.home-project-category {
  @include h4;
  letter-spacing: 1.3px;
  margin-bottom: 8px;
  font-weight: 500;
}
.home-project-title {
  display: block;
  font-weight: bold;
  margin: 0px 0px 5px 0px;
  font-size: 1.2em;
  letter-spacing: 0.95px;
  line-height: 1.18em;
  text-decoration: none;
  color: $brand-primary;

  &:visited {
    color: $brand-primary;
  }
}
.home-project-details {
  width: 50%;
  @media screen and ( max-width: 1000px ) {
    width: 100%;
  }
}
.home-project-description {
  margin: 15px 0px 29px 0px;
  font-size: 1em;
  letter-spacing: 0.81px;
  line-height: 1.35em;
}
.featured-content {
  margin-top: 47px;
  margin-bottom: 45px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: $screen-md) {
    flex-wrap: wrap;
  }
  // @media screen and ( max-width: 1000px ) {
  //   flex-direction: column;
  // }
}
.featured-content-4 {
  margin-top: 54px;
  display: flex;
  .column:first-of-type {
    flex-grow: 1;
  }
  @media screen and ( max-width: 1000px ) {
    flex-direction: column-reverse;
  }
}
.feature-content-image {
  background-size: cover;
  width: 100%;
  height: 276px;
}
.featured-content-4 .feature-content-image {
  width: 276px;
  @media screen and ( max-width: $screen-sm ) {
    width: 100%;
  }
}
.feature-content-title {
  font-size: 1.2em;
  line-height: 1.25em;
  display: block;
  font-weight: bold;
  color: $brand-primary;
  text-decoration: none;
  letter-spacing: 0.95px;
  &:visited {
    color: $brand-primary;
  }
}
.feature-content-category {
  @include h4;
  letter-spacing: 1.3px;
  margin-bottom: 8px;
  margin-top: 25px;
  font-weight: 500;
}
.feature-content-link {
  display: inline-block;
  text-decoration: none;
  color: $brand-primary;
  line-height: 1.33em;
  margin-top: 10px;
  font-size: 1em;
  padding-bottom: 4px;
  border-bottom: 1px solid $link-underline-color;
}
.feature-content-description {
  line-height: 1.33em;
  margin-right: 40px;
  margin-top: 10px;
  p:nth-of-type(n + 2) {
    display: none;
  }
  @media screen and ( max-width: $screen-md ) {
    margin-right: 0px;
  }
}
.featured-content-1, .featured-content-2, .featured-content-3 {
  a {
    text-decoration: none;
  }
  width: 30%;
  @media screen and (max-width: $screen-md) {
    width: calc(50% - 5%);
    margin-right: 5%;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 550px) {
    width: 100%;
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.featured-content-4 {
  @media screen and (min-width: $screen-lg) {
    .feature-content-category {
      margin-top: 0;
    }
  }
}